import fetch from 'auth/GenericFetchInterceptor'

const DashboardService = {}



/* DASHBOARD SCADENZE PAZIENTI*/
DashboardService.getVisiteInScadenza = function (dataInizio, dataFine,puntiVendita) {
    const queryStringArray = puntiVendita.map(store => `&filters[patient][stores][id][$in]=${store.id}`);
    return fetch({
        url: `/api/visits?pagination[limit]=-1&fields[0]=data_visita&fields[1]=is_hidden&filters[is_hidden][$null][$nei]=true&filters[data_visita][$lte]=${dataFine}&filters[data_visita][$gte]=${dataInizio}&populate[patient][fields][0]=nome&populate[patient][fields][1]=cognome&filters[patient][tipo_paziente][$ne]=DECEDUTO&populate[patient][populate][0]=visits${queryStringArray.join('')}`,
        method: 'GET'
    })

}
DashboardService.getScadenzeGaranzie = function (dataInizio, dataFine,puntiVendita) {
    const queryStringArray = puntiVendita.map(store => `&filters[stores][id][$in]=${store.id}`);
    return fetch({
        url: `/api/patients?pagination[limit]=-1&fields[0]=nome&fields[1]=cognome&fields[2]=scadenza_garanzia&filters[scadenza_garanzia][$gte]=${dataInizio}&filters[scadenza_garanzia][$lte]=${dataFine}${queryStringArray.join('')}`,
        method: 'GET'
    })

}
DashboardService.getEsamiInScadenza = function (puntiVendita) {
    return fetch({
        url: `/api/audiomedical/esami-in-scadenza`,
        method: 'POST',
        data: {puntiVendita: puntiVendita}
    })
}

/*DASHBOARD STATISTICHE*/
DashboardService.getStatistichePazienti = function (puntiVendita,annoDiVendita) {
    return fetch({
        url: `/api/audiomedical/statistiche-paziente`,
        method: 'POST',
        data: {puntiVendita: puntiVendita,annoDiVendita:annoDiVendita}
    })
}
DashboardService.getStatisticheProvenienze = function (puntiVendita,annoDiVendita) {
    return fetch({
        url: `/api/audiomedical/statistiche-provenienze`,
        method: 'POST',
        data: {puntiVendita: puntiVendita,annoDiVendita:annoDiVendita}
    })
}
DashboardService.hideVisitsService = function (data,id) {
    return fetch({
        url: `/api/visits/${id}`,
        method: 'PUT',
        data: data
    })
}
DashboardService.countVisits = function (puntiVendita) {
    return fetch({
        url: `/api/audiomedical/count-visits`,
        method: 'POST',
        data: {puntiVendita: puntiVendita}
    })
}
/* ######################## SEZIONE SCADENZE VENDITE ######################## */

DashboardService.getRateInScadenza = function (dataInizio, dataFine,puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[purchase][store][id]=${store.id}`);
    return fetch({
        url: `/api/sales-histories?pagination[limit]=-1&filters[scadenza_rata][$lte]=${dataFine}&filters[scadenza_rata][$gte]=${dataInizio}&filters[rata_saldata]=false&populate[purchase][populate][patient][fields][0]=nome&populate[purchase][populate][patient][fields][1]=cognome${puntiVenditaFilters.join('')}`,
        method: 'GET'
    })

}
DashboardService.getRateScadute = function (dataInizio,puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[purchase][store][id]=${store.id}`);
    return fetch({
        url: `/api/sales-histories?pagination[limit]=-1&filters[scadenza_rata][$lt]=${dataInizio}&filters[rata_saldata]=false&populate[purchase][populate][patient][fields][0]=nome&populate[purchase][populate][patient][fields][1]=cognome${puntiVenditaFilters.join('')}`,
        method: 'GET'
    })

}

/* ######################## SEZIONE VENDITE #############################*/

/* CALCOLATORE INCASSI E FATTURATO */
/*
### OLD INCASSI ###
DashboardService.getIncassi = function (tipologia_pagamento,store,data_inzio,data_fine) {
    return fetch({
        url: `/api/purchases?pagination[limit]=-1&filters[soluzione_pagamento]=${tipologia_pagamento}&filters[data_partenza][$lte]=${data_fine}&filters[data_partenza][$gte]=${data_inzio}&filters[store][id]=${store}`,
        method: 'GET',
    })
}
DashboardService.getIncassiPiuSoluzioni = function (store,data_inzio,data_fine) {
    return fetch({
        url: `/api/sales-histories?pagination[limit]=-1&filters[scadenza_rata][$lte]=${data_fine}&filters[scadenza_rata][$gte]=${data_inzio}&filters[rata_saldata]=true&filters[purchase][store][id]=${store}`,
        method: 'GET',
    })
}
*/
DashboardService.getSaleHistorySaldati = function (store,data_inzio,data_fine) {
    return fetch({
        url: `/api/sales-histories?pagination[limit]=-1&filters[scadenza_rata][$lte]=${data_fine}&filters[scadenza_rata][$gte]=${data_inzio}&filters[purchase][store][id]=${store}&filters[rata_saldata]=true`,
        method: 'GET',
    })
}

DashboardService.getPurchaseFromTo = function (store,data_inzio,data_fine) {
    return fetch({
        url: `/api/purchases?pagination[limit]=-1&filters[data_vendita][$lte]=${data_fine}&filters[data_vendita][$gte]=${data_inzio}&filters[store][id]=${store}`,
        method: 'GET',
    })
}
/* FINANZIAMENTI */

DashboardService.getAllSaleHistoryNoWelded = function (puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[purchase][store][id]=${store.id}`);
    return fetch({
        url: `/api/sales-histories?pagination[limit]=-1&filters[rata_saldata]=false&populate[purchase][0]=store&populate[purchase][populate][0]=store${puntiVenditaFilters.join('')}`,
        method: 'GET',
    })
}
DashboardService.getRatePersonalizzate = function (puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[store][id]=${store.id}`);
    return fetch({
        url: `/api/purchases?pagination[limit]=-1&filters[soluzione_pagamento]=RATE PERSONALIZZATE&populate[0]=store&populate[1]=sales_histories${puntiVenditaFilters.join('')}`,
        method: 'GET',
    })
}
DashboardService.getAllSaleHistoryCustonWelded = function (puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[purchase][store][id]=${store.id}`);
    return fetch({
        url: `/api/sales-histories?pagination[limit]=-1&filters[rata_saldata]=false&populate[purchase][0]=store&populate[purchase][populate][0]=store${puntiVenditaFilters.join('')}`,
        method: 'GET',
    })
}
DashboardService.contaRateizzazioniInCorso = function (puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[store][id]=${store.id}`);
    return fetch({
        url: `/api/purchases?pagination[limit]=-1&filters[soluzione_pagamento]=PIU SOLUZIONI&populate[0]=store&filters[sales_histories][rata_saldata][$eq]=false${puntiVenditaFilters.join('')}`,
        method: 'GET',
    })
}
/*DashboardService.getTotaleFinanziamenti = function (puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[purchase][store][id]=${store.id}`);
    return fetch({
        url: `/api/sales-histories?pagination[limit]=-1&filters[rata_saldata]=false&populate[purchase][populate][0]=store${puntiVenditaFilters.join('')}`,
        method: 'GET',
    })
}*/
/*DashboardService.getTotaleFinanziamentiPerStore = function (store) {
    return fetch({
        url: `/api/sales-histories?pagination[limit]=-1&filters[rata_saldata]=false&filters[purchase][store][id]=${store}`,
        method: 'GET',
    })
}*/

/* ANDAMENTO VENDITE */

/*UTILITA VENDITE*/
DashboardService.getPazientiInPagamento = function (puntiVendita) {
    const queryStringArray = puntiVendita.map(store => `&filters[stores][id][$in]=${store.id}`);
    return fetch({
        url: `/api/patients?pagination[limit]=-1&fields[0]=nome&fields[1]=cognome&fields[2]=stato_paziente&fields[3]=tipo_paziente&filters[stato_paziente][$contains]=IN PAGAMENTO&populate=purchases&filters[purchases][$notNull]=false${queryStringArray.join('')}`,
        method: 'GET'
    })
}
export default DashboardService;