import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {errorMessage} from "../../utils/messages";
import DashboardService from "../../services/DashboardService";
import moment from 'moment';

export const initialState = {
    //SCADENZE IN GARANZIA
    loadingScadenzeInGaranzia: false,
    scadenzeInGaranzia: [],
    loadedScadenzeInGaranzia: false,
    errorScadenzeInGaranzia: false,
    //VISITE IN SCADENZA
    loadingScadenzeVisite: false,
    visiteInScadenza: [],
    loadedScadenzeVisite: false,
    errorScadenzeVisite: false,
    //SCADENZA ESAMI
    loadingScadenzaEsami: false,
    esamiInScadenza:[],
    loadedScadenzaEsami: false,
    errorScadenzaEsami: false,
    //RATE IN SCADENZA
    loadingRateInScadenza: false,
    rateInScadenza:[],
    loadedRateInScadenza: false,
    errorRateInScadenza: false,
    //RATE SCADUTE
    loadingRateScadute: false,
    rateScadute:[],
    loadedRateScadute: false,
    errorRateScadute: false,
    //VENDITE
    loadingPazientiInPagamento: false,
    pazientiInPagamento:[],
    loadedPazientiInPagamento: false,
    errorPazientiInPagamento: false
};


export const loadScadenzeInGaranzia = createAsyncThunk('carica-scadenze-in-garanzia', async (data, {rejectWithValue}) => {
    console.log("[loadScadenzeInGaranzia]");
    const {puntiVendita} = data;
    try {
        //SCADENZE IN GARANZIA DI QUESTO MESE
        const dataInizioScadenze = moment(moment().format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD');
        const dataFineScadenze = moment(moment().format('YYYY-MM-DD')).endOf('month').format('YYYY-MM-DD');
        const scedenzeInGaranziaPromise = DashboardService.getScadenzeGaranzie(dataInizioScadenze,dataFineScadenze,puntiVendita)
        const [scedenzeInGaranzia] = await Promise.all([scedenzeInGaranziaPromise]);
        return scedenzeInGaranzia.data;

    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare le scadenze In Garanzia. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const loadVisiteInScadenza = createAsyncThunk('carica-visite-in-scadenza', async (data, {rejectWithValue}) => {
    console.log("[loadVisiteInScadenza]");
    const {puntiVendita} = data;
    try {
        //DATA INZIZIO E DATA FINE VISITE IN SCADENZA DI SEI MESI FA
        const dataInizio = moment(moment().format('YYYY-MM-DD')).subtract(6, "months").startOf('month').format('YYYY-MM-DD');
        const dataFine = moment(moment().format('YYYY-MM-DD')).subtract(6, "months").endOf('month').format('YYYY-MM-DD');
        const visiteInScadenzaPromise = DashboardService.getVisiteInScadenza(dataInizio, dataFine, puntiVendita);
        const [visiteInScadenza] = await Promise.all([visiteInScadenzaPromise]);
        //CALCOLO VISITE EFFETTIVE (aggiunto successivamente per osservazione di mike)
        const visiteEffettive = calcolaVisiteEffettive(visiteInScadenza)
        return visiteEffettive.data;

    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare le visite in scadenza. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const loadEsamiInScadenza = createAsyncThunk('carica-esami-in-scadenza', async (data, {rejectWithValue}) => {
    console.log("[loadEsamiInScadenza]");
    const {puntiVendita} = data;
    try {

        const esamiInScadenzaPromise = DashboardService.getEsamiInScadenza(puntiVendita);
        const [esami] = await Promise.all([esamiInScadenzaPromise]);
        return esami.esami;

    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare gli esami in scadenza. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const loadRateInScadenza = createAsyncThunk('carica-rate-in-scadenza', async (data, {rejectWithValue}) => {
    console.log("[loadRateInScadenza]");
    const {puntiVendita} = data;
    try {
        //SCADENZE IN GARANZIA DI QUESTO MESE
        const dataInizioScadenze = moment(moment().format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD');
        const dataFineScadenze = moment(moment().format('YYYY-MM-DD')).endOf('month').format('YYYY-MM-DD');
        const rateInScadenzaPromise = DashboardService.getRateInScadenza(dataInizioScadenze,dataFineScadenze,puntiVendita)
        const [reateInScadenza] = await Promise.all([rateInScadenzaPromise]);
        const rateInScadenzaRaggruppate = reateInScadenza.data.reduce((acc, item) => {
            const purchaseId = item.attributes.purchase.data.id;

            let existingGroup = acc.find(group => group.purchaseId === purchaseId);

            if (!existingGroup) {
                existingGroup = {
                    purchaseId: purchaseId,
                    purchase: item.attributes.purchase.data.attributes,
                    items: []
                };
                acc.push(existingGroup);
            }
            existingGroup.items.push(item);

            return acc;
        }, []);
        //return reateInScadenza.data;
        return rateInScadenzaRaggruppate
    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare le rate in scadenza. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const loadRateScadute = createAsyncThunk('carica-rate-scadute', async (data, {rejectWithValue}) => {
    console.log("[loadRateScadute]");
    const {puntiVendita} = data;
    try {
        //SCADENZE IN GARANZIA DI QUESTO MESE
        const dataInizioMese = moment(moment().format('YYYY-MM-DD')).startOf('month').format('YYYY-MM-DD');

        const rateScadutePromise = DashboardService.getRateScadute(dataInizioMese,puntiVendita)
        const [rateScadute] = await Promise.all([rateScadutePromise]);

        const rateScaduteRaggruppate = rateScadute.data.reduce((acc, item) => {
            const purchaseId = item.attributes.purchase.data.id;

            let existingGroup = acc.find(group => group.purchaseId === purchaseId);
            if (!existingGroup) {
                existingGroup = {
                    purchaseId: purchaseId,
                    purchase: item.attributes.purchase.data.attributes,
                    items: []
                };
                acc.push(existingGroup);
            }

            // Aggiungi l'item al gruppo esistente
            existingGroup.items.push(item);

            return acc;
        }, []);
        //console.log("rateScaduteRaggruppate",rateScaduteRaggruppate)
        //return rateScadute.data;
        return rateScaduteRaggruppate;
    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare le rate scadute. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})
export const loadPazientiInPagamento = createAsyncThunk('carica-pazienti-in-pagamento', async (data, {rejectWithValue,getState}) => {
    console.log("[loadPazientiInPagamento]");
    try {
        const puntivendita = getState().puntiVendita.puntiVendita
        const pazientiInPagamento = await DashboardService.getPazientiInPagamento(puntivendita)
        return pazientiInPagamento.data;
    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i pazienti. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})


export const hideVisitSlice = createAsyncThunk('nascondi-visita-in-scadenza', async (input, {rejectWithValue, getState}) => {
    try {
        console.log("[hideVisitSlice]")
        const id = input.id
        const visiteInScadenzaPre = getState().dashboardScadenze.visiteInScadenza;
        const visiteInScadenzaPost = visiteInScadenzaPre.filter(visita => visita.id !== id)
        const data = {
            "data":{
                "is_hidden":true
            }
        }
        DashboardService.hideVisitsService(data,id)

        return visiteInScadenzaPost

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});
const calcolaVisiteEffettive = (visiteInScadenza) =>{
    //LOGICA PER ESTRARRE LE EFFETTIVE
    let visiteEffettiveInScadenzaArray = []
    visiteInScadenza.data.map((visitaInScadenza)=>{
        const dataVisita =visitaInScadenza.attributes.data_visita
        const visitsOfPatient = visitaInScadenza.attributes.patient.data.attributes.visits.data
        const dataMaggioreTrovata = visitsOfPatient.some(oggetto => {
            return moment(oggetto.attributes.data_visita).isAfter(dataVisita);
        });
        if(!dataMaggioreTrovata){
            visiteEffettiveInScadenzaArray.push(visitaInScadenza)
        }
    })

    return {data:visiteEffettiveInScadenzaArray,meta:visiteInScadenza.meta}
}
export const dashboardScadenze = createSlice({
    name: 'dashboardScadenze', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(loadScadenzeInGaranzia.pending, (state) => {
                state.loadingScadenzeInGaranzia = true
                state.loadedScadenzeInGaranzia = false
                state.errorScadenzeInGaranzia = false
            })
            .addCase(loadScadenzeInGaranzia.fulfilled, (state, action) => {
                state.loadingScadenzeInGaranzia = false
                state.loadedScadenzeInGaranzia = true
                state.scadenzeInGaranzia = action.payload
                state.errorScadenzeInGaranzia = false

            })
            .addCase(loadScadenzeInGaranzia.rejected, (state, action) => {
                state.loadingScadenzeInGaranzia = false
                state.loadedScadenzeInGaranzia = true
                state.scadenzeInGaranzia = []
                state.errorScadenzeInGaranzia = true
            })

            .addCase(loadVisiteInScadenza.pending, (state) => {
                state.loadingScadenzeVisite = true
                state.loadedScadenzeVisite = false
                state.errorScadenzeVisite = false
            })
            .addCase(loadVisiteInScadenza.fulfilled, (state, action) => {
                state.loadingScadenzeVisite = false
                state.loadedScadenzeVisite = true
                state.visiteInScadenza = action.payload
                state.errorScadenzeVisite = false
            })
            .addCase(loadVisiteInScadenza.rejected, (state, action) => {
                state.loadingScadenzeVisite = false
                state.loadedScadenzeVisite = true
                state.visiteInScadenza = []
                state.errorScadenzeVisite = true
            })
            .addCase(loadEsamiInScadenza.pending, (state) => {
                state.loadingScadenzaEsami = true
                state.loadedScadenzaEsami = false
                state.errorScadenzaEsami = false

            })
            .addCase(loadEsamiInScadenza.fulfilled, (state, action) => {
                state.loadingScadenzaEsami = false
                state.loadedScadenzaEsami = true
                state.esamiInScadenza = action.payload
                state.errorScadenzaEsami = false
            })
            .addCase(loadEsamiInScadenza.rejected, (state, action) => {
                state.loadingScadenzaEsami = false
                state.loadedScadenzaEsami = true
                state.esamiInScadenza = []
                state.errorScadenzaEsami = true
            })
            .addCase(loadRateInScadenza.pending, (state) => {
                state.loadingRateInScadenza = true
                state.loadedRateInScadenza = false
                state.errorRateInScadenza = false
            })
            .addCase(loadRateInScadenza.fulfilled, (state, action) => {
                state.loadingRateInScadenza = false
                state.loadedRateInScadenza = true
                state.rateInScadenza = action.payload
                state.errorRateInScadenza = false
            })
            .addCase(loadRateInScadenza.rejected, (state, action) => {
                state.loadingRateInScadenza = false
                state.loadedRateInScadenza = true
                state.esamiInScadenza = []
                state.errorRateInScadenza = true
            })
            .addCase(loadRateScadute.pending, (state) => {
                state.loadingRateScadute = true
                state.loadedRateScadute = false
                state.errorRateScadute = false
            })
            .addCase(loadRateScadute.fulfilled, (state, action) => {
                state.loadingRateScadute = false
                state.loadedRateScadute = true
                state.rateScadute = action.payload
                state.errorRateScadute = false
            })
            .addCase(loadRateScadute.rejected, (state, action) => {
                state.loadingRateScadute = false
                state.loadedRateScadute = true
                state.rateScadute = []
                state.errorRateScadute = true
            })
            .addCase(loadPazientiInPagamento.pending, (state) => {
                state.loadingPazientiInPagamento = true
                state.loadedPazientiInPagamento = false
                state.errorPazientiInPagamento = false
            })
            .addCase(loadPazientiInPagamento.fulfilled, (state, action) => {
                state.loadingPazientiInPagamento = false
                state.loadedPazientiInPagamento = true
                state.pazientiInPagamento = action.payload
                state.errorPazientiInPagamento = false
            })
            .addCase(loadPazientiInPagamento.rejected, (state, action) => {
                state.loadingPazientiInPagamento = false
                state.loadedPazientiInPagamento = true
                state.pazientiInPagamento = []
                state.errorPazientiInPagamento = true
            })
            .addCase(hideVisitSlice.fulfilled,(state,action)=>{
                state.visiteInScadenza = action.payload
            })

    }
})


export default dashboardScadenze.reducer;