import React from 'react'
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/reset.css'

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
if (process.env.REACT_APP_ENV !== ("sviluppo" &&  "development") && process.env.REACT_APP_ENV !== undefined ) console.log = () => {};

root.render(
    <App/>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
