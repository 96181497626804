import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {errorMessage} from "../../utils/messages";
import CalendarService from "../../services/CalendarService";
import moment from "moment";
import "moment/locale/it";


export const initialState = {

    startReportMancanti: null,
    endReportMancanti: null,
    loadingReportMancanti: false,
    loadedReportMancanti: false,
    errorReportMancanti: false,
    reportMancanti: []
}


const filterAppointmentsWithoutSameDayVisit = (appointments) => {
    return appointments.filter((appointment) => {
        const appointmentDate = new Date(appointment.attributes.start).toISOString().split("T")[0];
        const visits = appointment?.attributes?.patient?.data?.attributes?.visits?.data;
        if(visits===undefined){
            return false
        }
        const hasSameDayVisit = visits.some((visit) => {
            const visitDate = visit.attributes.data_visita;
            return appointmentDate === visitDate;
        });
        return !hasSameDayVisit;

    });
};
export const calculateReportMancanti = createAsyncThunk('report-mancanti', async (input, {
    rejectWithValue,
    getState
}) => {

    console.log("[calculateReportMancanti]")
    try {
        let start = null
        let end = null
        let effectiveEnd = null
        if (input === undefined || input.start === null || input.end === null) {
            start = moment(moment(moment()).startOf('day')).subtract(3, 'days').format("YYYY-MM-DD")
            end = moment(moment(moment()).startOf('day')).format("YYYY-MM-DD")
            effectiveEnd = (moment(moment(moment()).startOf('day'))).add(1,'days').format("YYYY-MM-DD")
        } else {
            start = input.start
            end = input.end
            effectiveEnd = moment(end).add(1,'days').format("YYYY-MM-DD")
        }

        const puntivendita = getState().puntiVendita.puntiVendita
        const response = await CalendarService.getReport(puntivendita, start, effectiveEnd);
        return {
            data: filterAppointmentsWithoutSameDayVisit(response.data),
            start: start,
            end: end
        }

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i report. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const ReportMacantiSlice = createSlice({
    name: 'reportMancanti',
    initialState,
    reducers: {
        resetState: (state, action) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(calculateReportMancanti.pending, (state) => {
                state.loadingReportMancanti = true
                state.loadedReportMancanti = false
                state.errorReportMancanti = false
            })
            .addCase(calculateReportMancanti.fulfilled, (state, action) => {
                state.loadingReportMancanti = false
                state.reportMancanti = action.payload.data
                state.startReportMancanti = action.payload.start
                state.endReportMancanti = action.payload.end
                state.loadedReportMancanti = true
                state.errorReportMancanti = false
            })
            .addCase(calculateReportMancanti.rejected, (state, action) => {
                state.loadingReportMancanti = false
                state.reportMancanti = []
                state.loadedReportMancanti = true
                state.errorReportMancanti = true
            });


    }
})


export default ReportMacantiSlice.reducer;