import {combineReducers} from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import pazienti from './slices/pazientiSlice'
import schedaPaziente from "./slices/schedaPazienteSlice";
import puntiVendita from "./slices/puntiVenditaSlice";
import provenienze from "./slices/ProvenienzeSlice"
import pazientiStatiTipi from "./slices/pazientiStatiTipiSlice"
import dashboardStatistiche from "./slices/StatisticheSlices";
import dashboardScadenze from "./slices/ScadenzeSlices";
import dashboardVendite from "./slices/VenditeSlices";
import prodotti from "./slices/prodottiSlice"
import events from "./slices/CalendarSlice"
import utentiAndRoles from "./slices/utentiAndRolesSlice";
import reportMancanti from "./slices/ReportMancantiSlice";
import richiami from "./slices/RichiamiSlice";

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        pazienti,
        schedaPaziente,
        puntiVendita,
        provenienze,
        pazientiStatiTipi,
        dashboardStatistiche,
        dashboardScadenze,
        dashboardVendite,
        prodotti,
        events,
        utentiAndRoles,
        reportMancanti,
        richiami,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer;