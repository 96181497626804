import {DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig'
import {
    PAZIENTI_ERROR,
    PAZIENTI_LOADED,
    PAZIENTI_MAP_VALUE,
    PAZIENTI_STATI_TIPI_ERROR,
    PAZIENTI_STATI_TIPI_LOADED,
    PAZIENTI_STATI_TIPI_VALUE,
    PAZIENTI_VALUE,
    PROVENIENZE_ERROR,
    PROVENIENZE_LOADED,
    PROVENIENZE_VALUE,
    PUNTI_VENDITA_LOADED,
    PUNTI_VENDITA_VALUE,
    USERS_VALUE
} from "../constants/AuthConstant";

export const APP_NAME = 'Audiomedical';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/scadenze`;
export const UNAUTHENTICATED_ENTRY = '/login'

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR,
    blankLayout: false
};


/*** STATI REDUX ***/
export const PAZIENTI = {
    loadingPazienti: false,
    loadedPazienti: JSON.parse(localStorage.getItem(PAZIENTI_LOADED)) || false,
    pazienti: JSON.parse(localStorage.getItem(PAZIENTI_VALUE)) || [],
    pazientiMap: JSON.parse(localStorage.getItem(PAZIENTI_MAP_VALUE)) || {},
    errorPazienti: JSON.parse(localStorage.getItem(PAZIENTI_ERROR)) || false
};

export const UTENTI = {
    loadingUtenti: false,
    loadedUtenti: false,
    utenti: [],
    errorUtenti: false
};

export const UTENTI_AND_ROLES = {
    loadingUtentiAndRoles: false,
    loadedUtentiAndRoles: false,
    utentiAndRoles: JSON.parse(localStorage.getItem(USERS_VALUE)) || [],
    errorUtentiAndRoles: false
};

export const EVENTI = {
    loadingEvents: false,
    loadedEvents: false,
    events: [],
    errorEvents: false,
};
export const SCHEDA_PAZIENTE = {
    loadingSchedaPaziente: false,
    loadedSchedaPaziente: false,
    schedaPaziente: {},
    errorSchedaPaziente: false,
    defaultTabKey: 1
};

export const PUNTI_VENDITA = {
    loadingPuntiVendita: false,
    loadedPuntiVendita: localStorage.getItem(PUNTI_VENDITA_LOADED) || false,
    puntiVendita: JSON.parse(localStorage.getItem(PUNTI_VENDITA_VALUE)) || [],
    errorPuntiVendita: JSON.parse(localStorage.getItem(PAZIENTI_ERROR)) || false
};

export const PROVENIENZE = {
    loadingProvenienze: false,
    loadedProvenienze: localStorage.getItem(PROVENIENZE_LOADED) || false,
    provenienze: JSON.parse(localStorage.getItem(PROVENIENZE_VALUE)) || [],
    errorProvenienze: JSON.parse(localStorage.getItem(PROVENIENZE_ERROR)) || false
};

export const PAZIENTI_STATI_TIPI = {
    loadingPazientiStatiTipi: false,
    loadedPazientiStatiTipi: Boolean(localStorage.getItem(PAZIENTI_STATI_TIPI_LOADED)) || false,
    pazientiStatiTipi: JSON.parse(localStorage.getItem(PAZIENTI_STATI_TIPI_VALUE)) || [],
    errorPazientiStatiTipi: Boolean(JSON.parse(localStorage.getItem(PAZIENTI_STATI_TIPI_ERROR))) || false
};

export const ASSEGNAZIONE_INTERNA = [
    {
        title: "audioprotesista",
        id: "audioprotesista",
        type: "select",
        options: [""],
        rules: [
            {
                required: true,
                message: 'Inserire un nome obbligatorio',
            }
        ]
    }
]

export const MAIL = [
    {
        title: "email",
        type: "input",
        rules: [
            {
                type: 'email',
                message: 'Inserisci una mail nel formato valido!',
            }
        ]
    }
]

export const SEDE = [
    {
        title: "email",
        type: "input",
        rules: [
            {
                type: 'email',
                message: 'Inserisci una mail nel formato valido!',
            }
        ]
    }
]

export const ANAGRAFICA = [
    {
        title: "nome",
        type: "input",
        rules: [
            {
                required: true,
                message: 'Inserire un nome obbligatorio',
            }
        ]
    },
    {
        title: "cognome",
        type: "input",
        rules: [
            {
                required: true,
                message: 'Inserire un cognome obbligatorio',
            }
        ]
    },
    {
        title: "nazione",
        type: "input"
    },
    {
        title: "cellulare",
        type: "input",
        rules: [
            {
                required: true,
                message: 'Inserire un numero di cellulare obbligatorio',
            }
        ]
    },
    {
        title: "sesso",
        type: "select",
        options: ["Maschio", "Femmina"]
    },
    {
        title: "telefono_fisso",
        type: "input"
    },
    {
        title: "telefono_ufficio",
        type: "input"
    },
    {
        title: "data_di_nascita",
        type: "date"
    },
    {
        title: "cellulare_alternativo",
        type: "input"
    },
    {
        title: "luogo_di_nascita",
        type: "input"
    },
    {
        title: "provincia_di_residenza",
        type: "input"
    },
    {
        title: "citta_di_residenza",
        type: "input"
    },
    {
        title: "indirizzo",
        type: "input"
    },

]

export const DATI_SUPPLEMENTARI = [
    {
        title: "studio_di_riferimento",
        type: "select",
        mode: "multiple",
        options: [""],
        rules: [
            {
                required: true,
                message: 'Inserire uno studio di riferimento',
            }
        ]
    },
    {
        title: "tipo_provenienza",
        type: "select",
        mode: "single",
        options: [""]
    },
    {
        title: "nome_provenienza",
        type: "select",
        mode: "single",
        options: [""],
        disabled: true
    },
    {
        title: "note",
        type: "text"
    },
]

export const DATI_ASL = [
    {
        title: "data_di_autorizzazione",
        type: "date"
    },
    {
        title: "data_di_collaudo",
        type: "date"
    },
    {
        title: "scadenza_garanzia",
        type: "date"
    },
]

export const VISITE = [
    {
        title: "tipo_provenienza",
        type: "select",
        options: [""],
        rules: [
            {
                required: true,
                message: 'Inserire il tipo di provenienza',
            }
        ],
        hidden: true
    },
    {
        title: "nome_provenienza",
        type: "select",
        options: [""],
        rules: [
            {
                required: true,
                message: 'Inserisci il nome della provenienza scelta',
            }
        ],
        hidden: false
    },
    {
        title: "anamnesi",
        type: "text"
    },
    {
        title: "data_visita",
        type: "date",
        rules: [
            {
                required: true,
                message: 'Inserisci una data obbligatoria',
            }
        ]
    }
]


export const VENDITE = [
    {
        title: "titolo_vendita",
        type: "input",
        rules: [
            {
                required: true,
                message: 'Inserire il nome della vendita',
            }
        ],
    },
    {
        title: "data_vendita",
        type: "date",
        hidden: false,
        rules: [
            {
                required: true,
                message: 'Inserire la data della vendita',
            }
        ],
    },
    {
        title: "dispositivo",
        type: "select",
        options: [""],
        hidden: false
    },
    {
        title: "accessorio",
        type: "select",
        options: [""],
        hidden: false
    },
    {
        title: "punti_vendita",
        type: "select",
        options: [""],
        hidden: false,
        rules: [
            {
                required: true,
                message: 'Inserire lo store legato alla vendita',
            }
        ],
    },
    {
        title: "privacy",
        type: "checkbox",
        hidden: false,
    },
]

export const VENDITE_PAGAMENTI = [
    {
        title: "importo",
        simbolo: true,
        type: "price",
        rules: [
            {
                required: true,
                message: 'Inserire un importo valido',
            }
        ],
    },
    {
        title: "acconto",
        type: "price",
        simbolo: true,
    },

    {
        title: "ente",
        type: "input",
    },
    {
        title: "mutua",
        simbolo: true,
        type: "price",
    },
    {
        title: "data_scadenza_acconto",
        type: "date",
        hidden: false,
        rules: [
            {
                required: false,
                message: 'Inserire la data di scadenza acconto',
            }
        ],
    },
    {
        title: "data_scadenza_mutua",
        type: "date",
        hidden: false,
        rules: [
            {
                required: false,
                message: 'Inserire la data di scadenza mutua',
            }
        ],
    },
]


export const VENDITE_MODALITA = [
    {
        title: "modalita_pagamento",
        type: "select",
        options: ["BONIFICO", "CONTANTI", "BOLLETTINI", "RID", "ASSEGNI", "POS"],
        hidden: false,
        rules: [
            {
                required: true,
                message: 'Inserire il metodo di pagamento',
            }
        ],
    },
    {
        title: "soluzione_pagamento",
        type: "select",
        options: ["SOLUZIONE UNICA", "PIU SOLUZIONI", "FINANZIAMENTO", "RATE PERSONALIZZATE"],
        hidden: false,
        rules: [
            {
                required: true,
                message: 'Inserire la soluzione di pagamento',
            }
        ],
    },
    {
        title: "data_scadenza_pagamento_unico",
        type: "date",
        hidden: true,
        rules: [
            {
                required: true,
                message: 'Inserire la data di scadenza del pagamento',
            }
        ],
    },
    {
        title: "numero_rate",
        type: "price",
        simbolo: false,
        min: 1,
        hidden: true,
        rules: [
            {
                required: true,
                message: 'Inserire il numero di rate',
            }
        ],
    },
    {
        title: "modalita_rate",
        type: "select",
        options: ["MENSILE", "BIMESTRALE", "TRIMESTRALE", "SEMESTRALE"],
        hidden: true,
        rules: [
            {
                required: true,
                message: 'Inserire la tipologia di rate',
            }
        ],
    },
    {
        title: "partenza_pagamento",
        type: "date",
        hidden: true,
        rules: [
            {
                required: true,
                message: 'Inserire la data di partenza delle rate',
            }
        ],
    },
]

export const RATA = [
    {
        title: "rata",
        simbolo: true,
        type: "price",
        rules: [
            {
                required: true,
                message: 'Inserire un importo valido',
            }
        ],
    },
    {
        title: "data_scadenza_rata",
        type: "date",
        hidden: false,
        rules: [
            {
                required: true,
                message: 'Inserire la data di pagamento/scadenza rata',
            }
        ],
    },
    {
        title: "saldata",
        type: "checkbox",
        hidden: false,
    },

]

export const NEW_USER_AUTORIZZATO = [
    {
        title: "username",
        type: "input",
        rules: [
            {
                required: true,
                message: 'Inserire un username valido',
            }
        ]
    },
    {
        title: "telefono",
        type: "input"
    },
    {
        title: "email",
        type: "mail",
        rules: [
            {
                required: true,
                message: 'Inserire una mail valida',
            }
        ]
    },
    {
        title: "role",
        type: "select",
        options: [""],
        rules: [
            {
                required: true,
                message: 'Inserire un ruolo valido',
            }
        ]
    }
]

export const CHANGE_PASSWORD = [
    {
        title: "username",
        type: "input",
        rules: [
            {
                required: true,
                message: 'Inserire un username valido',
            }
        ]
    },
    {
        title: "telefono",
        type: "input"
    },
    {
        title: "email",
        type: "mail",
        rules: [
            {
                required: true,
                message: 'Inserire una mail valida',
            }
        ]
    },
    {
        title: "role",
        type: "select",
        options: [""],
        rules: [
            {
                required: true,
                message: 'Inserire un ruolo valido',
            }
        ]
    }
]

export const ANNI_VENDITA = [
    "2010", "2011", "2012",
    "2013", "2014", "2015",
    "2016", "2017", "2018",
    "2019", "2020", "2021",
    "2022", "2023", "2024",
    "2025", "2026", "2027",
    "2028", "2029", "2030",
]


export const STATI_TIPI = [
    {
        title: "tipo_paziente",
        type: "select",
        options: [""]
    },
    {
        title: "stato_paziente",
        type: "select",
        options: [""],
    },
    {
        title: "anno_di_vendita",
        type: "select",
        options: ANNI_VENDITA,
    },
    {
        title: "sede",
        type: "select",
        options: ["Pomigliano d'Arco", "Nola", "Torre del Greco", "Sorrento"],
    },
]


export const PRODOTTI = {
    loadingProdotti: false,
    prodotti: [],
    loadedProdotti: false,
    errorProdotti: false
}

export const TYPE_PAZIENTI = {
    loadingTypesPazienti: false,
    typesPazienti: [],
    loadedTypesPazienti: false,
    errorTypesPazienti: false
};