import fetch from 'auth/GenericFetchInterceptor'

const ProvenienzeService = {}


ProvenienzeService.getProvenienze = function () {
    return fetch({
        url: `/api/sources?&pagination[limit]=-1`,
        method: 'GET'
    })
}

ProvenienzeService.inserisciProvenienza = function (data) {
    return fetch({
        url: `/api/sources`,
        method: 'POST',
        data:data
    })
}
ProvenienzeService.deleteProvenienza = function (id) {
    return fetch({
        url: `/api/sources/${id}`,
        method: 'DELETE',
    })
}
ProvenienzeService.modificaProvenienza = function (id,data) {
    return fetch({
        url: `/api/sources/${id}`,
        method: 'PUT',
        data:data
    })
}



export default ProvenienzeService;